.userBox .btn-primary {
    color: inherit !important;
    background-color: transparent !important;
    border: none !important;
    /* And so on... */
}

.userBox .dropdown-toggle {
    color: inherit !important;
    background-color: transparent !important;
    border: none !important;
    /* And so on... */
}

.userBox .btn.dropdown-toggle::after {
    display: none !important;
}