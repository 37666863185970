.timeline-item {
    position: absolute;
    background-color: #8884D8; /* Or any color you want */
    color: #FFF;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    white-space: nowrap;
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Center vertically */
    max-height: 100px; /* Limit the height */
    overflow: hidden; /* Hide the vertical overflow */
    z-index: 4; 
}

.timeline-content {
    text-align: center;
    padding: 0 5px; /* Add padding to prevent text touching the edges */
}

.timeline-content h2,
.timeline-content p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0; /* Remove default margins */
    display: block; /* Ensure block level for proper ellipsis */
    max-width: 100%; /* Prevent overflow */
}

.date {
    display: block;
    margin-top: 5px;
    color: #FFF;
}

.pop-up-box {
    display: none; /* Hide by default */
    position: absolute;
    bottom: 120%; /* Position above the timeline item */
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    background-color: #333; /* Example background color */
    color: white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
    z-index: 10; /* Ensure it's above other items */
    white-space: normal; /* Allow wrapping in the pop-up */
    min-width: 100px; /* Minimum width of the pop-up box */
}

.timeline-item:hover .pop-up-box {
    display: block; /* Show on hover */
}
