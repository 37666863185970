.timeline-container {
    position: relative;
    width: 500px;
    height: 220px;
    margin: 0 auto;
    overflow: visible; /* changed to visible to allow the popup to appear outside the container */
    background-color: #FFF; /* Example background color */
    z-index: 1; /* Establish a new stacking context */
  }
  
  .chart-background {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2; /* Ensure it's above the timeline-container background */
  }
  
  .time-slot {
    position: relative;
    flex: 0 0 20%; /* five time slots so each takes up 20% */
    border-left: 1px solid #828282; /* Change color as needed */
    z-index: 2; /* Same level as chart-background */
  }
  
  .time-slot:first-child {
    border-left: none;
  }
  
  .time-slot span {
    position: absolute;
    bottom: 10px; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    color: #828282; /* Change color as needed */
    z-index: 3; /* Above the lines */
  }
  