/* AiMenu.module.css */
  .icon {
    font-size: 40px;
    width: 40px;
    height: 40px;
  }
  .aiMenu {
    width: 80px;
    // transition: width 0.3s ease-in-out;
    height: 100%;
    background-color: #0A1929;
    padding-top: 28px;
  }
  .aiMenuClosed {
    composes: aiMenu;
    // transition: width 0.3s ease-in-out;
    width: 80px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: center;
  }
  
  .sideitem {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    color: #B2BAC2;
    text-decoration: none;
    overflow: hidden;
  }
  
  .linkText {
    padding-left: 16px;
  }
  
  .sideitem:hover {
    background-color: #244f7d1c;
  }
  
  .menuBtn {
    align-self: center;
    align-self: flex-start;
    justify-self: flex-end;
    color: #B2BAC2;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 20px;
  }
  
  .subMenu {
    padding-left: 3px;
  }
  

  .metismenu {
    background: #0A1929;
    font-family: 'Open Sans', sans-serif;
    font-size: 14pt;
    overflow: hidden;
    position: relative;
  }
  .metismenu > .metismenucontainer > .metismenuitem > .metismenulink {
    line-height: 2.5em;
  }
  .metismenu > .metismenucontainer > .metismenuitem > .metismenulink .metismenustate-icon {
    line-height: 2.5em;
  }
  .metismenu::after {
    box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.5) inset;
    -webkit-box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.5) inset;
    content: " ";
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  .metismenucontainer,
  .metismenuitem {
    margin: 0;
    padding: 0;
  }
  .metismenucontainer {
    list-style: none;
  }
  .metismenucontainer .metismenucontainer {
    box-shadow: 0 0.3em 0.3em -0.3em #0c0d0e inset, 0 -0.3em 0.3em -0.3em #0c0d0e inset;
    -webkit-box-shadow: 0 0.3em 0.3em -0.3em #0c0d0e inset, 0 -0.3em 0.3em -0.3em #0c0d0e inset;
    transition: padding 300ms;
    -webkit-transition: padding 300ms;
    background: rgba(255, 255, 255, 0.05);
  }
  .metismenucontainer .metismenucontainer .metismenuitem > .metismenulink {
    height: 0;
    overflow: hidden;
  }
  .metismenucontainer .metismenucontainer .metismenulink {
    padding-left: 1em;
  }
  .metismenucontainer .metismenucontainer .metismenucontainer .metismenulink {
    padding-left: 2em;
  }
  .metismenucontainer.visible {
    padding: .5em 0;
  }
  .metismenucontainer.visible > .metismenuitem > .metismenulink {
    height: 2.5em;
  }
  .metismenulink {
    color: #DDD;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
    -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
    transition: height 300ms, color 300ms, background-color 300ms;
    -webkit-transition: height 300ms, color 300ms, background-color 300ms;
    display: block;
    line-height: 2.5em;
    text-decoration: none;
    margin-bottom:0px;
  }
  .metismenulink:hover {
    background: rgba(255, 255, 255, 0.05);
    color: #f7f7f7;
  }
  .metismenulink.active {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background: #F53;
    color: #FFF;
  }
  .metismenulink.has-active-child {
    color: #F53;
  }
  I.metismenuicon {
    text-align: center;
    width: 3em;
  }
  I.metismenustateicon {
    transition: transform 300ms;
    -webkit-transition: transform 300ms;
    float: right;
    line-height: 2.5em;
    text-align: center;
    width: 3em;
  }
  I.metismenustateicon.rotate-minus-90 {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
  }
  