.pf-filter {
    /* width: 70%; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    /* background-color:red; */
}

.pf-filter-menu-container {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* flex-direction: column; */
    border-bottom: 0.5px solid rgb(225, 230, 233);
    margin-bottom: 15px;
    position: relative;
    transition: width 0.2s;
    /* background-color: rgba(229, 232, 235, 0.297) !important; */
}

.pf-filter-container {
    position: absolute;
    right: -400px;
    width: 350px;
    /* width: 30%; */
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-left: 1px solid #E8ECEF;
    box-sizing: border-box;
    transition: all 0.2s;
    /* overflow: auto; */
    background-color: white;
    box-shadow: -0px 0px 30px 1px rgba(43, 43, 43, 0.372);
}

.pf-filter-timerange-container {
    height: 30%;
    width: 100%;
    border-bottom: 3px solid #E8ECEF;
    display: flex;
    justify-content: flex-start; /* Align content to the left */
    /* background-color: antiquewhite; */
}

.pf-filter-time-series-container {
    width: 25%; /* Set width to 25% of the parent */
    height: 70%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    /* background-color: antiquewhite; */
    text-align: center;
}

.pf-filter-time-series-container .pf-filter-timerange-predefine {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr; /* Adjust to single column */
    grid-gap: 1px;
    padding: 10px; /* Optional padding for better spacing */
}

.pf-filter-timerange-predefine2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create two columns */
    grid-gap: 1px; /* Adjust the gap as needed */
    padding: 10px; /* Optional padding for better spacing */
}

.pf-filter-time-box {
    width: 100%; /* Set width to fill the container */
    height: 40px; /* Adjust height for better visual */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(241, 244, 247);
    cursor: pointer;
    border-radius: 1px;
    margin-bottom: 1px; /* Add spacing between buttons */
}

.checkbox-container {
    display: flex;
    flex-direction: column;
}

.pf-filter-check-box label {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    height: 40px; /* Adjust height for better visual */
    background-color: rgb(241, 244, 247);
    border-radius: 1px;
    margin-bottom: 1px; /* Add spacing between buttons */
}

.pf-filter-check-box input[type="checkbox"] {
    margin-left: 10px; /* Add some space between label text and checkbox */
    margin-right: 10px;
}

.time-box-selected {
    background-color: rgb(62, 64, 65);
}

.time-box-selected p {
    color: white;
    font-weight: 600 !important;
}

.pf-filter-time-box:hover {
    background-color: rgb(229, 232, 235);
}

.pf-filter-time-box p {
    font-weight: 400;
    font-size: 0.8em;
    letter-spacing: 0.03em;
}

 .pf-filter-description-text{
    font-size: 0.8em;
    color: #6A6A6A;
    padding-top: 15px;;
 }

 .pf-filter-datetime-picker-container {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    /* background-color: cadetblue; */
}

.pf-filter-datetime-picker {
    /* background-color: red */
    border-radius: 5px !important;
    /* border: 2px solid lawngreen !important; */
}

.pf-datetimerangepicker-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 1px 0;
  }
  
  .pf-datetimerangepicker-container > * > * {
    margin: 10px;
  }
  
  .pf-datetimerangepicker-content {
    display: flex;
    max-width: 100%;
    flex-basis: 420px;
    flex-direction: column;
    flex-grow: 100;
    align-items: stretch;
  }

.pf-list-header {
    font-size: 1em; /* Adjust to your preference */
    font-weight: bold; /* Making it bold like typical h4 */
    padding: 5px 5px; /* Add some padding */
    margin-bottom: 3px; /* Add margin for spacing */
    border-radius: 4px; /* Optional: rounded corners */
}


.pf-btn-container {
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10%;
}

.pf-btn-text {
    padding: 10px 20px;
    background-color: #04A9F2; /* Primary button color */
    color: white;
    border-radius: 5px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    text-align: center;
    font-size: 1em;
}

.pf-btn-text:hover {
    background-color: #0378D2; /* Darker shade for hover */
    color: #FFF;
    border-color: #FFF; /* Optional: change border color on hover */
}

.pf-btn-text:active {
    background-color: #0264B3; /* Even darker for pressed effect */
    color: #E2E2E2;
    border-color: #D2D2D2; /* Optional: change border color when pressed */
}


.pf-container h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    border-radius: 20px;
   /* padding: 50px, 30px; */
   box-sizing: border-box;
   color: white;
    background-color: #04A9F2;
    cursor: pointer;
}

.pf-loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(107, 109, 114, 0.434);
}

.pf-loading-container h4{
    font-weight: 500;
    font-size: 1.3em;
    margin-top: 30px;
}