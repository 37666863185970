// Linear Icons
$linearicons-font-path: "airconditioner";

@font-face {
  font-family: 'airconditioner';
  src:url('#{$linearicons-font-path}/glyph_Air_conditioner_icon_cs.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="air-"],
[class*=" air-"] {
  font-family: 'airconditioner';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.air-one:before {
  content: "\e100";
}
// .air-two:before {
//   content: "\e200";
// }
// .air-three:before {
//   content: "\e300";
// }
// .air-four:before {
//   content: "\e400";
// }
// .air-five:before {
//   content: "\e500";
// }
// .air-six:before {
//   content: "\e600";
// }
// .air-seven:before {
//   content: "\e700";
// }
// .air-eight:before {
//   content: "\e800";
// }