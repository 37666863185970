/* Customizing the primary button color */
.btn-primary {
    background-color: #007bff;  /* Primary color */
    border-color: #007bff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #0069d9; /* Darker shade for hover, focus, active states */
    border-color: #0062cc;
}

/* Customizing the secondary button color */
.btn-secondary {
    background-color: #6c757d; /* Secondary color */
    border-color: #6c757d;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
    background-color: #5a6268; /* Darker shade for hover, focus, active states */
    border-color: #545b62;
}