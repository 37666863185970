.ahu_type_1_wrapper {
    width:1135px;
    height:600px;
    background-position: calc(50% - 0px) calc(50% - 70px);
    background-size: auto;
    background-repeat: no-repeat;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0; 
    background-color: white;
    border-color: aquamarine;
    border-style: none;
}

.ahu-holder{
    margin: 0px 18px 18px 18px;
    position:relative;
    background-color: white;
    width: auto;
    height: 600px;
}

.titleName {
    width:100%;
    height:100%;
    color:black;
    text-align: center;
}