.modal-backdrop {
  backdrop-filter: none !important;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Adjust the background color as needed */
}

.rightAlignedModal {
  position: absolute;
  right: 50px; 
  width: 30%;
  top: 10%;
  bottom: 10%;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1050; 
}


.modal-header-custom,
.modal-body-custom,
.modal-footer-custom {
  padding: 0;
  margin: 0;
}

.modal-body-custom {
  padding: 15px; /* Adjust as needed */
  overflow-y: auto;
}
