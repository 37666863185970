.headerDots .btn-primary {
    color: inherit !important;
    background-color: transparent !important;
    border: none !important;
    /* And so on... */
}

.headerDots .dropdown-toggle {
    color: inherit !important;
    background-color: transparent !important;
    border: none !important;
    /* And so on... */
}

.headerDots .btn.dropdown-toggle::after {
    display: none !important;
}