
.image-box-wrapper {
    position: absolute;
    width: 85px;
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: contain;
}

.fcu_type_1_b__box-wrapper {
    position: absolute;
    width: 53px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.fcu_type_1_b__box-content { 
    border-radius: 10px;
    width: 100%;
    height: 100%;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 5px; */
    background-color: white;
    border: 1px solid lightgray;
    /* background-color: aqua !important; */
}

.fcu_type_1_b__box-value-text { 
    font-size: 0.8em;
    /* center horizontal hack */
    text-align: center;
    /* center horizontal hack */

    /* margin-top: 5px; */
    /* padding: 2px 7px; */
    /* border: 1px solid lightgrey; */
    /* background: white */
}

.fcu_type_1_b__small-box-wrapper {
    position: absolute;
    width: 100px;
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aliceblue; */
    /* flex-direction: column; */
}

.fcu_type_1_b__box-wrapper p{
    font-size: 0.65em;
    /* flex-direction: column; */
}

.fcu_type_1_b__small-box-wrapper p{
    font-size: 0.65em;
    /* flex-direction: column; */
}

.fcu_type_1_b__indicators-wrapper {
    min-width: 33%;
    height: 150px;
    margin-top: 30px;
    margin-bottom: 40px;
    /* display: flex;
    align-items: center; */
    /* padding: 20px 0; */
    box-sizing: border-box;
    /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
    /* background-color: rebeccapurple !important; */
}