.chart-container {
    display: flex;
    justify-content: center; /* Centers horizontally */
    width: 100%; /* Or any specific width */
}

.score-container {
    margin-top: -60px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    width: 100%; /* Or any specific width */
    text-align: center;
}

.intensity-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates 4 columns */
    grid-template-rows: repeat(1, 1fr); /* Creates 1 rows */
    gap: 10px; /* Adjust the gap between items as needed */
    width: 100%; /* Adjust width as needed */
    max-width: 560px; /* Or the maximum width you want for the container */
    margin: 20px auto; /* This will center the container in its parent */
  }