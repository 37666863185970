.fcu_type_1_wrapper {
    width: 1135px;
    height:360px;
    /* min-height: 420px; */
    background-position: calc(50%) calc(50%);
    background-size: auto;
    background-repeat: no-repeat;
    background-color: white;
    border-color: purple;
    border-style: none;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0; 
    /* display: inline-block;
    margin: 0 auto; */
  /* transform: translate(-10%,-27%); */
}

.fcu-holder{
    margin: 0px 18px 18px 18px;
    position:relative;
    /* display: table-cell; */
    background-color: white;
    width: auto;
    height: 360px;
    /* align-items: center;
    justify-content: center; */
}

.titleName {
    width:100%;
    height:100%;
    color:black;
    text-align: center;
}